.chat_message {
  position: relative;
  font-size: 16px;
  padding: 10px;
  width: fit-content;
  border-radius: 10px;
  background-color: #dfe2e5;
  margin-bottom: 30px;
  max-width: 70%;
}
.chat_reciever {
  margin-left: auto;
  background-color: #6c757d;
  color: white;
}
.chat_name {
  position: absolute;
  top: -15px;
  font-weight: 800;
  font-size: xx-small;
  color: black;
}

.chat_timestamp {
  margin-left: 10px;
  font-size: xx-small;
}
