.login {
  max-width: 430px;
  background-color: white;
  display: flex;
  border-radius: 5px;
  align-items: center;
  padding: 30px 30px 10px 30px;
}

.login_header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 0;
}
.login_header > h2 {
  margin: 0 0 0 15px;
  flex: 0.9;
}
.row {
  /* border-style: dashed;
  border-width: 1px;
  border-color: red; */
}
.disclamier {
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.4);
  margin: 0 0 2px 0;
}

.login_header > .MuiAvatar-root {
  margin-left: 0;
}
