.sidebarChat {
  display: flex;
  padding: 20px;
  cursor: pointer;
  border-bottom: 1px solid #f6f6f6;
}

.sidebarChat:hover {
  background-color: #ebebeb;
}

.sidebarChat_info > h2 {
  font-size: 12px;
  margin-bottom: 8px;
}

.sidebarChat_info {
  margin-left: 15px;
}
