.chat {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.chat_header {
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid lightgray;
}
.chat_headerInfo {
  flex: 1;
  padding-left: 20px;
}

.chat_headerInfo > h3 {
  margin-bottom: 3px;
  font-weight: 500;
}

.chat_headerInfo > p {
  color: gray;
}

.chat_body {
  flex: 1;
  background-repeat: repeat;
  background-position: center;
  padding: 30px;
  overflow: scroll;
}

.chat_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 62px;
  border-top: 1px solid lightgray;
}
.chat_footer > form {
  flex: 1;
  display: flex;
}

.chat_footer > form > input {
  flex: 1;
  border-radius: 30px;
  padding: 10px;
  border: none;
  outline-width: 0;
}

.chat_footer > form > button {
  border-width: 0;
  background-color: transparent;
  /* this way we can still use the funcitonality of the button without it being visible.
  we wanna send the message when enter is pressed. */
}

.chat_footer > .MuiSvgIcon-root {
  padding: 10px;
  color: gray;
}
