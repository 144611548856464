.home {
  display: grid;
  place-items: center;
  height: 100vh;
  background-color: #adb5bd;
}

.home_body {
  display: flex;
  background-color: white;
  margin-top: -50px;
  height: 90vh;
  width: 90vw;
  box-shadow: -1px 4px 20px -6px rgba(0, 0, 0, 0.75);
}
